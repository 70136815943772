import React from "react";
import {Link  } from "react-router-dom";
import Layout from "./layout.js";
import * as Constants from "../config/constants";

import { useTranslation } from "react-i18next";

import servicesImg from "../assets/images/cyber-insurance-img.svg";

import servicesIcon1 from "../assets/images/risks-business-icon.svg";
import servicesIcon2 from "../assets/images/policy-review.svg";

import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Home({ pageInfo }) {

  const {t} = useTranslation();

  return (
    <Layout pageName="Cyber Insurance">
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">Cyber Insurance</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item"><Link  to={Constants.PRODUCTS_AND_SERVICES_PAGE_URL} title={t('products-services')}>{t('products-services')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Cyber Insurance</li>
          </ol>
        </div>
      </header>
      
      {/* Cyber Insurance  */}
      
      <section className="section-padding">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-4 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300">
              <img src={servicesImg} alt="Services" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-8 order-lg-2 order-1 common-right-div common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Cyber <span>Insurance</span></h3>
                <p className="common-desc">Our team specialises in assessing cyber risks and understanding the potential consequences of a cyber incident on any business. We offer cyber insurance services to:</p>
                <ul className="common-list list-full" style={{maxWidth: "100%",width: "100%", columns: "1"}}>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Insurers - Use our custom built SaaS tools to better underwrite cyber insurance specific to your policy coverage. Simplify your underwriting workflows and make underwriting decisions that are consistent with your appetite and treaty.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Brokers - Add value to your customers by analysing their cyber risks. This enables you to arrange the right insurer and RI for your client.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Insured - Understand your cyber insurance requirements based on your risks. This helps you reach out to the right brokers and insurers and buy the right insurance. We review your cyber insurance policy to ensure it aligns with your unique needs and provides adequate coverage.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding pt-3 services-section">
        <div className="container">
          <div className="row">
            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon1} alt="Cyber Risks and Business Impact Analysis" className="services-icon"/>
                </div>
                <h3 className="services-title">Cyber Risks and Business Impact Analysis</h3>
              </div>
              <div className="services-content-div">
                <p className="common-desc">Understanding the potential impact of cyber risks on your business is essential for effective risk management. Our Cyber Risk Assessment  and Business Impact Analysis service offers a comprehensive assessment of the potential consequences of a cyber incident on your organisation. We don't just focus on technical aspects, our analysis considers the broader business implications, including financial losses, operational disruptions, reputational damage, and regulatory penalties. By identifying and quantifying the potential impacts, we help you develop proactive strategies to mitigate risks and ensure business continuity in the face of cyber threats. </p>
              </div>
              <div className="border-shape"></div>
            </div>

            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon2} alt="Cyber Policy Review" className="services-icon"/>
                </div>
                <h3 className="services-title">Cyber Policy Review</h3>
              </div>
              <div className="services-content-div">
                <p className="common-desc">When it comes to cyber insurance, having the right policy in place is crucial. That's where our Cyber Policy Review service comes in. Our experienced team conducts a thorough evaluation of your existing cyber insurance policy to ensure it provides comprehensive coverage tailored to your business needs. We go beyond a simple checklist, our experts analyse the fine print and identify any gaps or limitations in your policy. Whether you're looking to update your coverage or switch providers, we provide you with actionable recommendations to ensure you're adequately protected against cyber threats.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      

    </Layout>
  );
}