var hostName = window.location.hostname;
var protocol = window.location.protocol;
var siteName = '';
var frontendFolder = '/frontend-react/';
var backendFolder = '/backend/';

export const LOGIN_COOKIE_NAME = "PENTAQUBE";
export const SITE_TITLE = "Pentaqube";
export const BASE_PATH = "/";
export const API_BASE_URL = protocol + "//" + hostName + siteName + backendFolder + "api/";
export const BACKEND_FILE_URL = protocol + "//" + hostName + siteName + backendFolder + "storage/app/uploads/";
export const SITE_URL = 'https://www.pentaqube.com/';
export const LANGUAGE_FOLDER_URL = SITE_URL ;