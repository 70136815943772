import React from "react";
import {Link  } from "react-router-dom";
import Layout from "../layout";

import { useTranslation } from "react-i18next";

import bannerImg from "../../assets/images/banner.svg";
import aboutImg from "../../assets/images/about-us.svg";

import businessContinuity from "../../assets/images/business-continuity.svg";
import leadingEdge from "../../assets/images/leading-edge-icon.svg";
import cyberInsurance from "../../assets/images/cyber-Insurance.svg";
import cyberSecurityTrainings from "../../assets/images/cyber-security-trainings.svg";
import securityManagement from "../../assets/images/security-management.svg";
import securityCompliance from "../../assets/images/security-compliance.svg";
import securityAssessments from "../../assets/images/security-assessments.svg";
import youAsk from "../../assets/images/you-ask.svg";

import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Home({ pageInfo }) {

  const {t} = useTranslation();

  return (
    <Layout pageName={t("home")}>

      <section className="section-padding">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-xl-7 col-lg-6 common-left-div common-content-col" data-aos="fade-right" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="first-titel"><span>Consulting</span> and <span>Tools</span> to Simplify your Cyber Security Requirements</h3>
                <p className="common-desc">From Routine GRC Implementations & security assessments to complex cyber risk quantifications and AI risk assessments, we help you simplify your cybersecurity, data privacy and business continuity.</p>
                <div className="common-btn-div">
                  <Link to={void(0)} className="common-btn" title="Know More">Know More</Link>
                  <Link to={void(0)} className="common-btn common-btn2" title="View Product">View Product</Link>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 common-right-div" data-aos="fade-left" data-aos-delay="300">
              <img src={bannerImg} alt="banner" className="common-img img-fluid"/>
            </div>
          </div>
        </div>
      </section>

      {/* about us  */}
      
      <section className="section-padding">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-5 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300">
              <img src={aboutImg} alt="About" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-7 order-lg-2 order-1 common-right-div common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">PentaQube <span>Cyber Technologies LLP</span></h3>
                <p className="common-desc">What do people with nearly a century of cybersecurity experience between them do? They step out of their comfort zone. They build upon something they already built from scratch. The co founders of PentaQube are building a cybersecurity firm with a difference</p>
                <div className="common-btn-div">
                  <Link to={void(0)} className="common-btn" title="Know More">Know More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* services section */}
      
      <section className="section-padding services-section">
        <div className="container">
          <div className="row justify-content-center" data-aos="fade-down">
            <div className="col-lg-8 center-title-div">
                <h3 className="main-title">Services <span>We Provide</span></h3>
            </div>
          </div>
          <div className="row row-gap20">
            <div className="col-lg-3 col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="100">
              <Link to={void(0)} className="services-item">
                <div className="services-icon-div">
                  <img src={businessContinuity} alt="Business Continuity Management" className="services-icon"/>
                </div>
                <h5 className="services-name">Business Continuity Management</h5>
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="200">
              <Link to={void(0)} className="services-item">
                <div className="services-icon-div">
                  <img src={leadingEdge} alt="NextGen Cyber Services" className="services-icon"/>
                </div>
                <h5 className="services-name">NextGen Cyber Services</h5>
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="300">
              <Link to={void(0)} className="services-item">
                <div className="services-icon-div">
                  <img src={cyberInsurance} alt="Cyber Insurance" className="services-icon"/>
                </div>
                <h5 className="services-name">Cyber Insurance</h5>
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="400">
              <Link to={void(0)} className="services-item">
                <div className="services-icon-div">
                  <img src={cyberSecurityTrainings} alt="Cyber Security Trainings" className="services-icon"/>
                </div>
                <h5 className="services-name">Cyber Security Trainings</h5>
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="100">
              <Link to={void(0)} className="services-item">
                <div className="services-icon-div">
                  <img src={securityManagement} alt="Cyber Security Management" className="services-icon"/>
                </div>
                <h5 className="services-name">Cyber Security Management</h5>
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="200">
              <Link to={void(0)} className="services-item">
                <div className="services-icon-div">
                  <img src={securityCompliance} alt="Cyber Security Compliance" className="services-icon"/>
                </div>
                <h5 className="services-name">Cyber Security Compliance</h5>
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="300">
              <Link to={void(0)} className="services-item">
                <div className="services-icon-div">
                  <img src={securityAssessments} alt="Cyber Security Assessments" className="services-icon"/>
                </div>
                <h5 className="services-name">Cyber Security Assessments</h5>
              </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="400">
              <div className="services-item2">
                <h5 className="services-title">Lorem</h5>
                <p className="services-desc">Lorem Lipsum is lorem lispum</p>
                <Link to={void(0)} title="View Product" className="common-btn common-btn2 mt-0">View Product</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ask section */}

      <section className="section-padding ask-section">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-7 common-left-div common-content-col" data-aos="fade-right" data-aos-delay="200">
              <div className="common-content-div">
                <h3 className="main-title">What’s the difference, <span>you ask?</span></h3>
                <p className="common-desc">Cybersecurity today, is either a checklist or a template driven exercise. We are a firm that automates the boring stuff. It takes you straight to the thinking part. We know that there are cybersecurity products galore. Most of them are complex behemoths that users never touch the surface of. We want to change that.</p>
                <p className="common-desc">We have always believed in putting the customer front and centre - we do that with design thinking. We've done personas and customer journey map exercises often. They help us build automated solutions that tackle the customers biggest problems. We plan to build more. We want to automate everything. Except your brains. We want to adopt the AI revolution and make cybersecurity better.</p>
                <div className="call-us-div">
                  <div className="call-us-icon">
                    <div className="call-us-icon-float1"></div>
                    <div className="call-us-icon-float2"></div>
                    <div className="call-us-icon-float3"></div>
                    <div className="call-us-icon-in">
                      <i className="fi fi-rr-phone-call"></i>
                    </div>
                  </div>
                  <div className="call-us-content">
                    <span className="call-us-title">Call Us Now</span>
                    <a href="tel:+910000000000" className="call-us-link">+91 00000 00000</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 common-right-div" data-aos="fade-left" data-aos-delay="200">
              <img src={youAsk} alt="About" className="common-img img-fluid"/>
            </div>
          </div>
        </div>
      </section>




    </Layout>
  );
}