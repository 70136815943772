import React from "react";
import {Link  } from "react-router-dom";
import Layout from "./layout.js";
import * as Constants from "../config/constants";

import { useTranslation } from "react-i18next";

import servicesImg from "../assets/images/cybersecurity-trainings.svg";

import servicesIcon1 from "../assets/images/risks-and-biases-humans-icon.svg";
import servicesIcon2 from "../assets/images/secure-coding-icon.svg";
import servicesIcon3 from "../assets/images/ai-risk-icon.svg";
import servicesIcon4 from "../assets/images/user-awareness-icon.svg";

import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Home({ pageInfo }) {

  const {t} = useTranslation();

  return (
    <Layout pageName="Trainings">
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">Trainings</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item"><Link  to={Constants.PRODUCTS_AND_SERVICES_PAGE_URL} title={t('products-services')}>{t('products-services')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Trainings</li>
          </ol>
        </div>
      </header>
      
      {/* Trainings  */}
      
      <section className="section-padding">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-4 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300">
              <img src={servicesImg} alt="Services" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-8 order-lg-2 order-1 common-right-div common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Trainings</h3>
                <ul className="common-list list-full" style={{maxWidth: "100%",width: "100%",}}>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Risks and Biases of Humans</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Secure Coding 101</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Secure software development</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Threat Modelling</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Phishing</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Cybersecurity for internal auditors</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Business Continuity Management </li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Crisis Management Workshops</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>AI Risk Management</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Cyber Risk Quantification</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>End User Awareness Trainings</li>
                </ul>
              </div>
            </div>
            <div className="col-12 order-3">
              <p className="common-desc">We love to impart what we have learnt and gained in all these years of being in the Cyber realm/space. </p>
              <p className="common-desc">We deliver training to businesses and end users of our services. Our training offering is a good mix of  topics that are routine but necessary along with some quite unique ones. We not only deliver training, but also conceptualise them so that they are completely tailored to address the requirements of our clients. We like to believe that we are partners in this endeavour and not just training solution providers. Give us an area of concern that you think can be addressed through training and you will see our creative juices get to work! </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding pt-3 services-section">
        <div className="container">
          <div className="row">
            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon1} alt="Risks and Biases of Humans" className="services-icon"/>
                </div>
                <h3 className="services-title">Risks and Biases of Humans</h3>
              </div>
              <div className="services-content-div">
                <p className="common-desc">Risk management is crucial not only from a compliance perspective but also for the actual value it brings to decision making. However the process of risk reporting is plagued by the filters that the humans reporting them carry. These filters aka biases can render the crucial step of accurate risk reporting useless. That is precisely what this training addresses. The objective of the training is to make risk teams aware of the biases. From confirmation bias to overconfidence, we explore how these biases manifest in risk management and provide actionable techniques to counteract them. </p>
              </div>
              <div className="border-shape"></div>
            </div>

            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon2} alt="Secure Coding 101" className="services-icon"/>
                </div>
                <h3 className="services-title">Secure Coding 101</h3>
              </div>
              <div className="services-content-div">
                <p className="common-desc">We do not teach developers to develop code (even we know our limitations!!), however we do help bridge coding with security. Our Secure Coding 101 course equips developers with essential knowledge and practical skills to integrate security seamlessly into the design and coding process. Through interactive workshops and hands-on exercises, participants learn the fundamental principles of secure coding, threat modelling, and some of the most trending coding mistakes and errors. We go beyond theoretical concepts, providing real-world examples and best practices to reinforce learning.  </p>
              </div>
              <div className="border-shape"></div>
            </div>

            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon3} alt="AI Risk Management" className="services-icon"/>
                </div>
                <h3 className="services-title">AI Risk Management</h3>
              </div>
              <div className="services-content-div">
                <p className="common-desc">Our workshop provides a comprehensive understanding of AI fundamentals, delving into emerging risks and vulnerabilities. Participants will explore techniques and governance frameworks to proactively address AI-related challenges. Through interactive discussions and practical case studies, attendees will gain invaluable insights into identifying, assessing, and managing AI risks in their organisations. By the workshop's conclusion, attendees will emerge equipped to confidently traverse the ever-changing AI terrain, bolstering system resilience and security.</p>
              </div>
              <div className="border-shape"></div>
            </div>

            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon4} alt="End User Awareness Trainings" className="services-icon"/>
                </div>
                <h3 className="services-title">End User Awareness Trainings</h3>
              </div>
              <div className="services-content-div">
                <p className="common-desc">We provide end user awareness training covering and not  limited to cybersecurity/information security, business continuity, HIPAA regulations. Our tailored programs equip participants with the knowledge and skills to uphold your organisation's policies and industry best practices. From recognizing and responding to cyber threats to maintaining operations during disruptions and safeguarding sensitive health information, our training ensures compliance and enhances your organisation's resilience.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      

    </Layout>
  );
}