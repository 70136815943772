import React from "react";
import {Link  } from "react-router-dom";
import Layout from "./layout.js";
import * as Constants from "../config/constants";

import { useTranslation } from "react-i18next";

import servicesImg from "../assets/images/crux.svg";

import servicesIcon1 from "../assets/images/past-probabilities-icon.svg";
import servicesIcon2 from "../assets/images/risk-report-icon.svg";
import servicesIcon3 from "../assets/images/easy-onboarding-icon.svg";
import servicesIcon4 from "../assets/images/multiple-reports-icon.svg";
import servicesIcon5 from "../assets/images/support-icon.svg";

import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Home({ pageInfo }) {

  const {t} = useTranslation();

  return (
    <Layout pageName="CRUX">
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">CRUX</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item"><Link  to={Constants.PRODUCTS_AND_SERVICES_PAGE_URL} title={t('products-services')}>{t('products-services')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">CRUX</li>
          </ol>
        </div>
      </header>
      
      {/* CRUX  */}
      
      <section className="section-padding">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-4 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300">
              <img src={servicesImg} alt="Services" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-8 order-lg-2 order-1 common-right-div common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title"><span>Transforming</span> Cyber Insurance <span>Underwriting with</span> CRUX</h3>
                <p className="common-desc">Discover a revolutionary approach to cyber underwriting with CruX (Cyber Risk Underwriting eXpert), a cutting-edge platform designed to address the challenges faced in managing cyber risks. At PentaQube, we believe in simplifying the complexity of cybersecurity and empowering underwriters to focus on what truly matters - effectively underwriting risks.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding pt-3 services-section">
        <div className="container">
          <div className="row">
          <h3 className="main-title"><span>Key Features of CruX:</span></h3>
            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon1} alt="Consider your Past Probabilities" className="services-icon"/>
                </div>
                <h3 className="services-title">Consider your Past Probabilities</h3>
              </div>
              <div className="services-content-div">
                <ul className="common-list" style={{columns: '1', maxWidth: '100%'}}>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>CruX helps you create a baseline by considering  your past claim numbers. Customise your probabilities with the most reliable source - your own past data.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Global Threat Reports: Uncover valuable insights from global threat reports, blending them with your claim numbers to identify base probabilities.</li>
                </ul>
              </div>
              <div className="border-shape"></div>
            </div>
            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon2} alt="Risk Report Based on Your Coverage" className="services-icon"/>
                </div>
                <h3 className="services-title">Risk Report Based on Your Coverage</h3>
              </div>
              <div className="services-content-div">
                <ul className="common-list" style={{columns: '1', maxWidth: '100%'}}>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>A report that is focused on YOUR product offerings. Not a generic score. Directly linking your policy coverage to risks.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Have a corporate and an SME product separate? No worries. CruX handles risk scoring for more than one product with customised mapping.</li>
                </ul>
              </div>
              <div className="border-shape"></div>
            </div>
            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon3} alt="Easy Onboarding and Streamlined Processes" className="services-icon"/>
                </div>
                <h3 className="services-title">Easy Onboarding and Streamlined Processes</h3>
              </div>
              <div className="services-content-div">
                <ul className="common-list" style={{columns: '1', maxWidth: '100%'}}>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Brokers, agents, and prospects can effortlessly register on the platform.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Seamless questionnaire exchange between brokers and prospects.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Proactive form requests for customers available through the insurer.</li>
                </ul>
              </div>
              <div className="border-shape"></div>
            </div>
            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon4} alt="Multiple Reports for Value Addition" className="services-icon"/>
                </div>
                <h3 className="services-title">Multiple Reports for Value Addition</h3>
              </div>
              <div className="services-content-div">
                <ul className="common-list" style={{columns: '1', maxWidth: '100%'}}>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Risk Reports for Underwriters: Comprehensive risk assessment reports.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Action Items Report for Prospective Buyers: Clear guidance on risk mitigation measures.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Attack Surface Analysis Report for Cybersecurity Consultants: In-depth analysis of vulnerabilities.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Consolidated Risk Report for Accumulation: Evaluate risk across your portfolio.</li>
                </ul>
              </div>
              <div className="border-shape"></div>
            </div>
            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon5} alt="PentaQube Support Throughout the Lifecycle" className="services-icon"/>
                </div>
                <h3 className="services-title">PentaQube Support Throughout the Lifecycle</h3>
              </div>
              <div className="services-content-div">
                <ul className="common-list" style={{columns: '1', maxWidth: '100%'}}>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Pre-Policy: PentaQube offers assistance in questionnaire completion and detailed assessments.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>During Policy: Monthly portfolio reviews and informative newsletters and trainings.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Claims: Incident response, analysis, forensics, and expert advice on claim admissibility.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Accumulation Computation: Calculate risk accumulation effectively.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      

    </Layout>
  );
}