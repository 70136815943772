import React from "react";
import {Link  } from "react-router-dom";
import Layout from "./layout.js";
import * as Constants from "../config/constants";

import { useTranslation } from "react-i18next";

import servicesImg from "../assets/images/cyber-security-management.svg";


import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Home({ pageInfo }) {

  const {t} = useTranslation();

  return (
    <Layout pageName="Data Privacy Management">
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">Data Privacy Management</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item"><Link  to={Constants.PRODUCTS_AND_SERVICES_PAGE_URL} title={t('products-services')}>{t('products-services')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Data Privacy Management</li>
          </ol>
        </div>
      </header>
      
      {/* Data Privacy Management  */}
      
      <section className="section-padding">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-4 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300">
              <img src={servicesImg} alt="Services" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-8 order-lg-2 order-1 common-right-div common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Data <span>Privacy Management</span></h3>
                <p className="common-desc">Each country or region typically establishes its own set of data privacy regulations that organisations must adhere to. Our data privacy experts will ensure personal data handled by your organisation is as per compliance requirements of various laws and regulations applicable to your organisation.</p>
                <p className="common-desc">Our experts develop detailed Data Flow Diagrams to map information flow, identify system interactions, and locate data repositories. Through Privacy Impact Assessments (PIA), we analyse PII collection, storage, and processing to mitigate privacy risks. </p>
                <p className="common-desc">Our services also include reviewing and documenting Data Privacy Policies to reflect evolving standards. Trust us to enhance your data privacy posture with expertise, customised solutions, and ongoing support.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      

    </Layout>
  );
}