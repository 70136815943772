import {React} from 'react';
import { Routes } from "react-router-dom";
import FrontendRoute from './routes/frontend-route.js';
import DesignRoute from './routes/design-route.js';
import "@flaticon/flaticon-uicons/css/all/all.css";

import './assets/js/common.js';
import './assets/css/bootstrap.min.css';
import './assets/css/main.css';
import './assets/css/style.css';

function App() {
  return (
    <div className="App" >
      <Routes>
        {FrontendRoute}
        {DesignRoute}
      </Routes>
    </div>
  );
}

export default App;
