import React from "react";
import {Link  } from "react-router-dom";
import Layout from "./layout.js";
import * as Constants from "../config/constants";

import { useTranslation } from "react-i18next";

import servicesImg from "../assets/images/leading-edge.svg";

import servicesIcon1 from "../assets/images/cyber-risk-quantification-icon.svg";
import servicesIcon2 from "../assets/images/ai-icon.svg";

import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Home({ pageInfo }) {

  const {t} = useTranslation();

  return (
    <Layout pageName="NextGen Cyber Services">
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">Cyber Services</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item"><Link  to={Constants.PRODUCTS_AND_SERVICES_PAGE_URL} title={t('products-services')}>{t('products-services')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">NextGen Cyber Services</li>
          </ol>
        </div>
      </header>
      
      {/* NextGen Cyber Services  */}
      
      <section className="section-padding">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-4 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300">
              <img src={servicesImg} alt="Services" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-8 order-lg-2 order-1 common-right-div common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">NextGen <span>Cyber Services</span></h3>
                <p className="common-desc">In today's rapidly evolving digital landscape, the ability to effectively manage cyber risks is key. We offer cutting-edge solutions like Cyber Risk Quantification and Artificial Intelligence (AI) Risk assessment. </p>
                <p className="common-desc">We simplify complex stuff for you.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding pt-3 services-section">
        <div className="container">
          <div className="row">
            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon1} alt="Cyber Risk Quantification" className="services-icon"/>
                </div>
                <h3 className="services-title">Cyber Risk Quantification</h3>
              </div>
              <div className="services-content-div">
                <p className="common-desc">Our risk quantification services enables better analyse and decision making:</p>
                <ul className="common-list list-full" style={{maxWidth: "100%"}}>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Data driven approach for  assessing and managing your  risks.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Get a  dollar value for  estimated losses.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Statically determine impacts & likelihood.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Make informed decisions, allocate resources effectively, and prioritise mitigation efforts.</li>
                </ul>
              </div>
              <div className="border-shape"></div>
            </div>

            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon2} alt="Artificial Intelligence Risk Assessment" className="services-icon"/>
                </div>
                <h3 className="services-title">Artificial Intelligence Risk Assessment</h3>
              </div>
              <div className="services-content-div">
                <p className="common-desc">We help organisations that leverage AI technology but are uncertain about potential risks:</p>
                <ul className="common-list list-full" style={{maxWidth: "100%"}}>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Understands challenges and vulnerabilities that may arise from adopting AI solutions.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Understand data security, privacy concerns, algorithmic biases and regulatory compliance  risks.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Implement an artificial intelligence management system (AIMS) like ISO42001 and NIST AI RMF.</li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </section>
      

    </Layout>
  );
}