import React from "react";
import Layout from "../layout";
import { useTranslation } from "react-i18next";

export default function SamplePage({ pageInfo }) {
  const { t } = useTranslation();
  
  return (
    <Layout pageName={t("sample-page")}>
      <header className="bg-secondary py-5">
        <div className="container py-5">
            <h1>{t("sample-page")}</h1>
        </div>
      </header>
      
      <section>
        {/* other content section wise  */}
      </section>
    </Layout>
  );
}