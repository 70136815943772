import React from "react";
import {Link  } from "react-router-dom";
import Layout from "./layout.js";
import * as Constants from "../config/constants";

import { useTranslation } from "react-i18next";

import servicesImg from "../assets/images/business-continuity-management.svg";


import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Home({ pageInfo }) {

  const {t} = useTranslation();

  return (
    <Layout pageName="Business Continuity Management">
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">Business Continuity Management</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item"><Link  to={Constants.PRODUCTS_AND_SERVICES_PAGE_URL} title={t('products-services')}>{t('products-services')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Business Continuity Management</li>
          </ol>
        </div>
      </header>
      
      {/* Business Continuity Management  */}
      
      <section className="section-padding">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-4 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300">
              <img src={servicesImg} alt="Services" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-8 order-lg-2 order-1 common-right-div common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Business <span>Continuity Management</span></h3>
                <p className="common-desc">We help you build a ship that continues to  sail even when it hits an ice-berg. We get companies compliant to ISO 22301 : 2019, widely known as the Business continuity management system standard. </p>
                <p className="common-desc">Our approach initiates by  facilitating an engaging design thinking workshop with the senior management of your organisation to pinpoint the critical impact parameters specific to your needs. An example of our work includes conducting such a workshop for a central bank of a country, where the identified impact parameter extended beyond mere just losing money or reputation-it affected the entire national economy.  We leverage the insights gathered from these workshops and impact assessment exercises to craft a continuity strategy and plans tailored precisely to your organisation's unique requirements. Our comprehensive approach not only safeguards your organisation's continuity but also fosters resilience and adaptability in the face of evolving threats and challenges. </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      

    </Layout>
  );
}