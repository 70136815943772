import * as twtConfig from "./twt_config";  

export const LOGIN_COOKIE_NAME = twtConfig.LOGIN_COOKIE_NAME;
export const API_BASE_URL = twtConfig.API_BASE_URL;
export const BACKEND_FILE_URL = twtConfig.BACKEND_FILE_URL;
export const SITE_URL = twtConfig.SITE_URL;
export const BASE_PATH = twtConfig.BASE_PATH;
export const LANGUAGE_FOLDER_URL = twtConfig.LANGUAGE_FOLDER_URL;
export const SITE_TITLE = twtConfig.SITE_TITLE;

/*
 *  Static Page Routes (i.e. About us, FAQ Page and other page where no development required)
 *
 */
 
export const LANDING_PAGE_URL = "/";
export const HOME_PAGE_URL = "/home.html";
export const ABOUT_US_PAGE_URL = "/about-us.html";
export const CONTACT_US_PAGE_URL = "/contact-us.html";
export const TEAM_PAGE_URL = "/team.html";
export const PRODUCTS_AND_SERVICES_PAGE_URL = "/products-and-services.html";
export const CYBERSECURITY_MANAGEMENT_PAGE_URL = '/cybersecurity-management.html';
export const CYBERSECURITY_COMPLIANCE_PAGE_URL = '/cybersecurity-compliance.html';
export const CYBER_TECHNICAL_ASSESSMENTS_PAGE_URL = '/cyber-technical-assessments.html';
export const BUSINESS_CONTINUITY_MANAGEMENT_PAGE_URL = '/business-continuity-management.html';
export const TRAININGS_PAGE_URL = '/trainings.html';
export const NEXTGEN_CYBER_SERVICES_PAGE_URL = '/nextgen-cyber-services.html';
export const CYBER_INSURANCE_PAGE_URL = '/cyber-insurance.html';
export const DATA_PRIVACY_MANAGEMENT_PAGE_URL = '/data-privacy-management.html';
export const OT_IOT_PAGE_URL = '/ot-and-iot.html';
export const CRUX_PAGE_URL = '/crux.html';
export const PRIVACY_POLICY_PAGE_URL = '/privacy-policy.html';

/*
 * Design Pages Route (Designer Only -- This will be removed once development is done)
 *
 */
 
export const SAMPLE_PAGE_URL = '/sample-page.html';
export const DESIGN_HOME_PAGE_URL = '/design-home.html';
export const DESIGN_CONTACT_US_PAGE_URL = '/design-contact-us.html';
/*
 * Dynamic Page Routes (i.e. Contact us page or any other page where form or any activity to be done by developer - By Developer Only)
 *
 */