import React from "react";
import {Link  } from "react-router-dom";
import Layout from "./layout";
import * as Constants from "../config/constants";
import { useTranslation } from "react-i18next";

import ChaitanyaKunthe from "../assets/images/chaitanya-kunthe.jpg";
import TruptiDesai from "../assets/images/trupti-desai.jpg";
import DeepanjaliKunthe from "../assets/images/deepanjali-kunthe.jpg";
import MayurJadhav from "../assets/images/mayur-jadhav.jpg";
import JerinJose from "../assets/images/jerin-jose.jpg";




import Linkedin from "../assets/images/linkedin.svg";


import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Home({ pageInfo }) {

  const {t} = useTranslation();


  return (
    <Layout pageName={t("team")}>
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">{t("team")}</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{t("team")}</li>
          </ol>
        </div>
      </header>
      <section className="section-padding pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 center-title-div" data-aos="fade-right" data-aos-delay="300">
              <h3 className="first-titel"><span>Meet</span> Our Team</h3>
              <p className="common-desc">At PentaQube, our leadership ensemble not only boasts profound professional expertise but also a captivating tapestry of interests and passions that imbue our company's ethos.</p>
            </div>
          </div>
        </div>
      </section>

    <section className="section-padding team-section pt-0">
      <div className="container">
        <div className="row team-item-row">
          <div className="col-12 team-item-col"  data-aos="fade-up">
            <div className="team-item">
              <div className="team-img-div">
                 <div className="team-img-inner">
                    <img src={ChaitanyaKunthe} alt="Chaitanya Kunthe" className="team-img team-img1 team-img-front"/>
                    <img src={ChaitanyaKunthe} alt="Chaitanya Kunthe" className="team-img team-img2 team-img-back"/>
                </div>
              </div>
              <div className="team-content-mdiv">
                <div className="team-content-header">
                  <div className="team-content-div">
                    <h3 className="team-member-name">Chaitanya Kunthe</h3>
                    {/* <p className="team-member-experience">20+ years in information security.</p> */}
                    <p className="team-member-designation">Chief Executive Officer</p>
                  </div>
                  <a href="https://linkedin.com/in/chaitanyakunthe/" className="team-social-link" target="_blank" rel="noopener noreferrer">
                    <img src={Linkedin} alt="Linkedin"/>
                  </a>
                </div>
                <div className="border-shape"></div>
                <p className="common-desc">Chaitanya Kunthe, our luminary Chief Executive Officer, orchestrates our company's trajectory with a profound commitment to continuous learning and innovation. Chaitanya's authored works, such as "Monkey, Shakespeare, Typewriter," stand as testaments to his profound insights and contributions to the field. Guided by his visionary leadership, our company surges forward, pioneering advancements in cyber insurance, risk quantification, and AI risk management, poised at the forefront of innovation and excellence. His core competence is his future-first attitude. He keeps PentaQube on the cutting edge of new services, technology and industry developments.</p>
                <p className="common-desc">Under his visionary guidance, we're driven to push boundaries and excel in every endeavour, shaping a future marked by progress and achievement.</p>
              </div>
            </div>
          </div>
          <div className="col-12 team-item-col"  data-aos="fade-up">
            <div className="team-item">
              <div className="team-img-div">
                 <div className="team-img-inner">
                    <img src={TruptiDesai} alt="Trupti Desai" className="team-img team-img1 team-img-front"/>
                    <img src={TruptiDesai} alt="Trupti Desai" className="team-img team-img2 team-img-back"/>
                </div>
              </div>
              <div className="team-content-mdiv">
                <div className="team-content-header">
                  <div className="team-content-div">
                    <h3 className="team-member-name">Trupti Desai</h3>
                    {/* <p className="team-member-experience">18+ years in information security.</p> */}
                    <p className="team-member-designation">Chief Operating Officer</p>
                  </div>
                  <a href="https://linkedin.com/in/trupti-desai-9a08585/" className="team-social-link" target="_blank" rel="noopener noreferrer">
                    <img src={Linkedin} alt="Linkedin"/>
                  </a>
                </div>
                <div className="border-shape"></div>
                <p className="common-desc">Trupti is a seasoned professional in the realm of information security boasting over 18 years of invaluable experience that brings a wealth of knowledge and expertise to the table.</p>
                <p className="common-desc">Trupti firmly believes in the power of teamwork. Whether collaborating with her internal team or assisting clients, she understands that success lies in joining forces to overcome obstacles and achieve common goals. One of her biggest strengths is her accurate perception of a client requirement making her an asset to the sales team as well. Heading the largest team in PentaQube along with being the COO her impeccable team handling skills are of aid to her in more ways than one. She is a multitasker and more importantly a multi-dimensional thinker giving a more rooted support to our CEOs vision.</p>
              </div>
            </div>
          </div>
          <div className="col-12 team-item-col"  data-aos="fade-up">
            <div className="team-item">
              <div className="team-img-div">
                 <div className="team-img-inner">
                    <img src={DeepanjaliKunthe} alt="Deepanjali Kunthe" className="team-img team-img1 team-img-front"/>
                    <img src={DeepanjaliKunthe} alt="Deepanjali Kunthe" className="team-img team-img2 team-img-back"/>
                </div>
              </div>
              <div className="team-content-mdiv">
                <div className="team-content-header">
                  <div className="team-content-div">
                    <h3 className="team-member-name">Deepanjali Kunthe</h3>
                    {/* <p className="team-member-experience">20+ years in information security.</p> */}
                    <p className="team-member-designation">Chief Learning Officer</p>
                  </div>
                  <a href="https://www.linkedin.com/in/deepanjali-kunthe-2255b51a9/" className="team-social-link" target="_blank" rel="noopener noreferrer">
                    <img src={Linkedin} alt="Linkedin"/>
                  </a>
                </div>
                <div className="border-shape"></div>
                <p className="common-desc">Deepanjali Kunthe, our vibrant Chief Learning Officer, injects a delightful blend of fun and creativity into our learning and training initiatives. </p>
                <p className="common-desc">She is always ready for a challenge whether it is conceptualising a training never done before or hosting company events. Her 15 plus years of consulting experience certainly helps in her understanding of training requirements. She enjoys developing training content with a touch of fun and games. Her training delivery is par excellence and helps customers achieve the desired outcome effectively.</p>
              </div>
            </div>
          </div>
          <div className="col-12 team-item-col"  data-aos="fade-up">
            <div className="team-item">
              <div className="team-img-div">
                 <div className="team-img-inner">
                    <img src={MayurJadhav} alt="Mayur Jadhav" className="team-img team-img1 team-img-front"/>
                    <img src={MayurJadhav} alt="Mayur Jadhav" className="team-img team-img2 team-img-back"/>
                </div>
              </div>
              <div className="team-content-mdiv">
                <div className="team-content-header">
                  <div className="team-content-div">
                    <h3 className="team-member-name">Mayur Jadhav</h3>
                    {/* <p className="team-member-experience">18+ years in information security.</p> */}
                    <p className="team-member-designation">Chief Growth Officer</p>
                  </div>
                  <a href="https://www.linkedin.com/in/mayurjadhav/" className="team-social-link" target="_blank" rel="noopener noreferrer">
                    <img src={Linkedin} alt="Linkedin"/>
                  </a>
                </div>
                <div className="border-shape"></div>
                <p className="common-desc">Mayur Jadhav, with nearly two decades of sales expertise across various industries, is renowned for his meticulous attention to detail and adeptness in crafting proposals and navigating contract negotiations. Forging connections with people is what makes him perfect for his role. An aficionado of offbeat destinations, Mayur's weekends are a blend of family time and exploration. With a commitment to work-life balance, Mayur approaches each day as an opportunity for discovery and delight for his cherished clientele.</p>
              </div>
            </div>
          </div>
          <div className="col-12 team-item-col"  data-aos="fade-up">
            <div className="team-item">
              <div className="team-img-div">
                 <div className="team-img-inner">
                    <img src={JerinJose} alt="Jerin Jose" className="team-img team-img1 team-img-front"/>
                    <img src={JerinJose} alt="Jerin Jose" className="team-img team-img2 team-img-back"/>
                </div>
              </div>
              <div className="team-content-mdiv">
                <div className="team-content-header">
                  <div className="team-content-div">
                    <h3 className="team-member-name">Jerin Jose</h3>
                    {/* <p className="team-member-experience">8+ years in information security.</p> */}
                    <p className="team-member-designation">Chief Products Officer</p>
                  </div>
                  <a href="https://linkedin.com/in/jerin-jose-0584b0b0/" className="team-social-link" target="_blank" rel="noopener noreferrer">
                    <img src={Linkedin} alt="Linkedin"/>
                  </a>
                </div>
                <div className="border-shape"></div>
                <p className="common-desc">Joining the ensemble is Jerin Jose, the youngest of the leadership team and our  Chief Products Officer.  Dedication to technological advancement is what propels our product development endeavours to unparalleled heights. His consulting experience has helped him to become the best Business Analyst in the game and his contributions in that role are invaluable. He is responsible for helping with conceptualising of products and subsequently bringing it to fruition making him a rare breed in his field. His methodical and systematic approach is his core strength and keeps his team away from undue pressures.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    </Layout>
  );
}