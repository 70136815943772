import React from "react";
import {Link  } from "react-router-dom";
import Layout from "./layout.js";
import * as Constants from "../config/constants";

import { useTranslation } from "react-i18next";

import servicesImg from "../assets/images/ot-and-iot.svg";

import servicesIcon1 from "../assets/images/ot-security-icon.svg";
import servicesIcon2 from "../assets/images/iot-security-icon.svg";

import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Home({ pageInfo }) {

  const {t} = useTranslation();

  return (
    <Layout pageName="OT and IoT">
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">OT and IoT</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item"><Link  to={Constants.PRODUCTS_AND_SERVICES_PAGE_URL} title={t('products-services')}>{t('products-services')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">OT and IoT</li>
          </ol>
        </div>
      </header>
      
      {/* OT and IoT  */}
      
      <section className="section-padding">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-4 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300">
              <img src={servicesImg} alt="Services" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-8 order-lg-2 order-1 common-right-div common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">OT <span>and IoT</span></h3>
                <p className="common-desc">We specialise in fortifying your organisation's IoT and OT ecosystem by assessing its security measures. Our assessments scrutinise the design and implementation of security controls within your environment, ensuring robust protection against emerging threats. </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding pt-3 services-section">
        <div className="container">
          <div className="row">
            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon1} alt="OT Security " className="services-icon"/>
                </div>
                <h3 className="services-title">OT Security </h3>
              </div>
              <div className="services-content-div">
                <p className="common-desc">Our OT Security Services focus on protecting vital infrastructure and industrial control systems from cyber threats. The service focuses on  helping you prevent cyberattacks, unauthorised access, and disruptions to operations.</p>

                <ul className="common-list" style={{columns: '1', maxWidth: '100%'}}>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Assessment of industrial control systems (ICS) and supervisory control and data acquisition (SCADA) system's security.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Assessment of OT networks and infrastructure cybersecurity posture.</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Assurance of compliance with industry standards and regulations for OT security for instance NIST SP 800-82.</li>
                </ul>
              </div>
              <div className="border-shape"></div>
            </div>
            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon2} alt="IoT security" className="services-icon"/>
                </div>
                <h3 className="services-title">IoT security</h3>
              </div>
              <div className="services-content-div">
                <p className="common-desc">Our IoT assessment service is a combination of technical and process based evaluation of your IoT implementation. we assess the IOT implementation based on the following steps:</p>

                <ul className="common-list" style={{columns: '1', maxWidth: '100%'}}>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>IoT security design review</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>IoT specific security policies and processes </li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Assessment of security controls implementation against industry guidelines (NIST, OWASP, etc.)</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Security analysis of devices</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>IoT application security assessment</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      

    </Layout>
  );
}