import React from "react";
import {Link  } from "react-router-dom";
import Layout from "./layout.js";
import * as Constants from "../config/constants";

import { useTranslation } from "react-i18next";

import servicesImg from "../assets/images/cybersecurity-compliance-regulatory.svg";


import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Home({ pageInfo }) {

  const {t} = useTranslation();

  return (
    <Layout pageName="Cybersecurity Compliance">
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">Cybersecurity Compliance</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item"><Link  to={Constants.PRODUCTS_AND_SERVICES_PAGE_URL} title={t('products-services')}>{t('products-services')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Cybersecurity Compliance</li>
          </ol>
        </div>
      </header>
      
      {/* Cybersecurity  Compliance  */}
      
      <section className="section-padding">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-4 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300">
              <img src={servicesImg} alt="Services" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-8 order-lg-2 order-1 common-right-div common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Cybersecurity <span>Compliance</span></h3>
                <p className="common-desc">If you've felt lost amidst all the compliance requirements, you've come to the right place.</p>
                <p className="common-desc">Navigating the complex landscape of cybersecurity regulatory compliance can be daunting, especially with regulations from multiple regulators. That's where we come in our team of experts has in-depth knowledge and experience in deciphering these requirements and assist your organisation in comprehending and effectively implementing these mandates.</p>
                <ul className="common-list list-full" style={{maxWidth: "600px", width: "100%",}}>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>GDPR Requirements</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>SEBI cybersecurity Requirements</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>IRDAI cybersecurity Requirements</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>RBI cybersecurity Requirements</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  );
}