import React from "react";
import {Link  } from "react-router-dom";
import Layout from "./layout.js";
import * as Constants from "../config/constants";

import { useTranslation } from "react-i18next";

import servicesImg from "../assets/images/cyber-security-management.svg";

import servicesIcon1 from "../assets/images/framework-implementation.svg";
import servicesIcon2 from "../assets/images/risk-assessment.svg";
import servicesIcon3 from "../assets/images/third-party-risk-management.svg";

import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Home({ pageInfo }) {

  const {t} = useTranslation();

  return (
    <Layout pageName="Cybersecurity Management">
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">Cybersecurity Management</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item"><Link  to={Constants.PRODUCTS_AND_SERVICES_PAGE_URL} title={t('products-services')}>{t('products-services')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Cybersecurity Management</li>
          </ol>
        </div>
      </header>
      
      {/* Cybersecurity Management  */}
      
      <section className="section-padding">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-4 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300">
              <img src={servicesImg} alt="Services" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-8 order-lg-2 order-1 common-right-div common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Managing your <span>Governance Framework</span></h3>
                <p className="common-desc">We love setting up and managing global governance frameworks.</p>
                <p className="common-desc">If you want to implement frameworks such as ISO 27001:2022, NIST CSF, PCI DSS, HIPAA, HITRUST etc. and assess risks pertaining to third party security our team of experts will help you.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding pt-3 services-section">
        <div className="container">
          <div className="row">
            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon1} alt="Framework Implementation" className="services-icon"/>
                </div>
                <h3 className="services-title">Framework Implementation</h3>
              </div>
              <div className="services-content-div">
                <p className="common-desc">We implement these frameworks in letter and spirit. We identify your key risks and suggest cost effective mitigation options for them.</p>
                <p className="common-desc common-desc-black">Some of the frameworks we have implemented in the past are:</p>

                <ul className="common-list">
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>ISO 27001 : 2022</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>NIST CSF</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>HITRUST</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>HIPAA</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>PCI DSS</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>ISO 27701</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>ISO 27017</li>
                </ul>

                <p className="common-desc">We also run and maintain these frameworks for you.</p>

              </div>
              <div className="border-shape"></div>
            </div>
            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon2} alt="Risk Assessment" className="services-icon"/>
                </div>
                <h3 className="services-title">Risk Assessment</h3>
              </div>
              <div className="services-content-div">
                <p className="common-desc">We suggest risk assessment frameworks only after considering your requirements & your current stage of framework maturity. We do not have a cookie cutter approach where one size fits all. If you already have a risk assessment framework, our team evaluates the same and can use the same if it meets the requirements.</p>

                <p className="common-desc">However, we have the expertise to go from a basic BOGSAT (Bunch Of Guys, Sitting Around Talking) risk assessment to a complex cyber risk quantification using standard methodologies like FAIR or even a mix of multiple approaches. It all depends on what you want and need.</p>

                <p className="common-desc">If you use an existing ERM framework, we can connect the ERM to your cybersecurity risk framework to ensure a seamless risk structure.</p>
                
              </div>
              <div className="border-shape"></div>
            </div>

            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon3} alt="Third Party Risk Management" className="services-icon"/>
                </div>
                <h3 className="services-title">Third Party Risk Management  </h3>
              </div>
              <div className="services-content-div">
                <p className="common-desc">We take away your pain in managing risk to your third party ecosystem.</p>
                <p className="common-desc">We have the experience and expertise to do that. We can set up your third party risk framework and assist you in assessing and reporting the risks to your third parties. We can ensure that your TAT's around assessing vendors are met.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      

    </Layout>
  );
}