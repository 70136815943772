import React from "react";
import { Route } from "react-router-dom";
import * as Constants from "../config/constants";
import Home from "../pages/home";
import Contact from "../pages/contact-us.js";
import About from "../pages/about-us.js";
import Team from "../pages/team.js";
import ProductsAndServices from "../pages/products-and-services.js";
import Error404 from "../pages/errors/404-error";
import CybersecurityManagement from "../pages/cybersecurity-management.js";
import CybersecurityCompliance from "../pages/cybersecurity-compliance.js";
import CyberTechnicalAssessments from "../pages/cyber-technical-assessments.js";
import BusinessContinuityManagement from "../pages/business-continuity-management.js";
import Trainings from "../pages/trainings.js";
import NextgenCyberServices from "../pages/nextgen-cyber-services.js";
import CyberInsurance from "../pages/cyber-insurance.js";
import DataPrivacyManagement from "../pages/data-privacy-management.js";
import OtIot from "../pages/ot-iot.js";
import Crux from "../pages/crux.js";
import PrivacyPolicy from "../pages/privacy-policy.js";


const FrontendRoute = [
    <Route key="frontend-1" path={Constants.LANDING_PAGE_URL} element={<Home />} />,
    <Route key="frontend-2" path={Constants.HOME_PAGE_URL} element={<Home />} />,
    <Route key="frontend-3" path={Constants.CONTACT_US_PAGE_URL} element={<Contact />} />,
    <Route key="frontend-4" path={Constants.ABOUT_US_PAGE_URL} element={<About />} />,
    <Route key="team" path={Constants.TEAM_PAGE_URL} element={<Team />} />,
    <Route key="team" path={Constants.PRODUCTS_AND_SERVICES_PAGE_URL} element={<ProductsAndServices />} />,
    <Route key="frontend-5" path="*" element={<Error404 />} />,
    <Route key="cybersecurity-management" path={Constants.CYBERSECURITY_MANAGEMENT_PAGE_URL} element={<CybersecurityManagement />} />,
    <Route key="cybersecurity-compliance" path={Constants.CYBERSECURITY_COMPLIANCE_PAGE_URL} element={<CybersecurityCompliance />} />,
    <Route key="cyber-technical-assessments" path={Constants.CYBER_TECHNICAL_ASSESSMENTS_PAGE_URL} element={<CyberTechnicalAssessments />} />,
    <Route key="business-continuity-management" path={Constants.BUSINESS_CONTINUITY_MANAGEMENT_PAGE_URL} element={<BusinessContinuityManagement />} />,
    <Route key="trainings" path={Constants.TRAININGS_PAGE_URL} element={<Trainings />} />,
    <Route key="nextgen-cyber-services" path={Constants.NEXTGEN_CYBER_SERVICES_PAGE_URL} element={<NextgenCyberServices />} />,
    <Route key="cyber-insurance" path={Constants.CYBER_INSURANCE_PAGE_URL} element={<CyberInsurance />} />,
    <Route key="data-privacy-management" path={Constants.DATA_PRIVACY_MANAGEMENT_PAGE_URL} element={<DataPrivacyManagement />} />,
    <Route key="ot-iot" path={Constants.OT_IOT_PAGE_URL} element={<OtIot />} />,
    <Route key="crux" path={Constants.CRUX_PAGE_URL} element={<Crux />} />,
    <Route key="privacy-policy" path={Constants.PRIVACY_POLICY_PAGE_URL} element={<PrivacyPolicy />} />,
    
];

export default FrontendRoute;