import React, { useEffect , useState } from "react";
import Header from './includes/header';
import Footer from './includes/footer';
import { seo } from '../seo';
import $ from 'jquery';
import * as Constants from "../config/constants";  
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {  apiCall , manageNetworkError } from "../assets/js/common";
window.jQuery = $;
window.$ = $;
global.jQuery = $;


export default function Layout({ children , pageName }) {
  const { t } = useTranslation(["translation", "errorMessage"]);
  const [settingInfo, setSettingInfo] = useState([]);

  const getSettingDetails = async () => {
		const getSettingInfo = await apiCall(  'post' ,  'getSettingInfo'  );
		if( getSettingInfo.status !== false ){
			setSettingInfo(getSettingInfo.data.data.settingInfo);
		} else {
			manageNetworkError(getSettingInfo);
		}
	}

  useEffect(() => {
    getSettingDetails();
  } , []);
  
  useEffect(() => {
    seo({
      title: pageName + " | " + (typeof(settingInfo.v_site_title) !== "undefined" && settingInfo.v_site_title !== null && settingInfo.v_site_title !== "" ? settingInfo.v_site_title : Constants.SITE_TITLE),
    });
  }, [t,settingInfo]);
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name='title' content={(pageName !== null && pageName !== "" ? pageName : 'Home' ) + ' | ' + (settingInfo.v_site_title !== null && settingInfo.v_site_title !== "" ? settingInfo.v_site_title : "")} />
        <meta name='description' content={settingInfo.v_site_description !== null && settingInfo.v_site_description !== "" ? settingInfo.v_site_description : ""} />
        <meta name='keywords' content={settingInfo.v_site_keywords !== null && settingInfo.v_site_keywords !== "" ? settingInfo.v_site_keywords : ""} />
        <meta name='author' content={settingInfo.v_meta_author !== null && settingInfo.v_meta_author !== "" ? settingInfo.v_meta_author : ""} />
        <meta name='copyright' content={settingInfo.v_site_title !== null && settingInfo.v_site_title !== "" ? settingInfo.v_site_title : ""} />
        <meta name='publisher' content={settingInfo.v_meta_author !== null && settingInfo.v_meta_author !== "" ? settingInfo.v_meta_author : ""} />
        
        <meta property='og:title' content={settingInfo.v_site_title !== null && settingInfo.v_site_title !== "" ? settingInfo.v_site_title : ""} />
        <meta property='og:description' content={settingInfo.v_site_description !== null && settingInfo.v_site_description !== "" ? settingInfo.v_site_description : ""} />
        <meta property="og:url" content={Constants.SITE_URL} />
        <meta property='og:site_name' content={settingInfo.v_site_title !== null && settingInfo.v_site_title !== "" ? settingInfo.v_site_title : ""} />
        {settingInfo.v_website_og_icon !== null && settingInfo.v_website_og_icon !== "" && (
          <meta property='og:image' content={Constants.BACKEND_FILE_URL + settingInfo.v_website_og_icon} />
        )}

        <meta property="twitter:url" content={Constants.SITE_URL} />
        <meta property='twitter:title' content={settingInfo.v_site_title !== null && settingInfo.v_site_title !== "" ? settingInfo.v_site_title : ""} />
        <meta property='twitter:description' content={settingInfo.v_site_description !== null && settingInfo.v_site_description !== "" ? settingInfo.v_site_description : ""} />
        {settingInfo.v_website_logo !== null && settingInfo.v_website_logo !== "" && (
          <meta property='twitter:image' content={Constants.BACKEND_FILE_URL + settingInfo.v_website_logo} />
        )}
        
        {settingInfo.v_website_fav_icon !== null && settingInfo.v_website_fav_icon !== "" && (
          <link rel="icon" href={Constants.BACKEND_FILE_URL + settingInfo.v_website_fav_icon} />
        )}
        {settingInfo.v_website_fav_icon !== null && settingInfo.v_website_fav_icon !== "" && (
          <link rel="apple-touch-icon" href={Constants.BACKEND_FILE_URL + settingInfo.v_website_fav_icon} />
        )}
      </Helmet>

      <Header settingInfo={settingInfo}/>
      <main>{children}</main> 
      <Footer settingInfo={settingInfo}/>
    </>
  )
}