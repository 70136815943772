import { SITE_TITLE } from "./config/constants";

export function seo(data = {}) {
	if(typeof(data.title) === "undefined"){
		data.title = "Loading...";
		document.title = "Loading...";
	} else {
		document.title = data.title;
	}
}
