import React,{useEffect, useState} from "react";
import {Link  } from "react-router-dom";
import Layout from "./layout.js";
import * as Constants from "../config/constants";
import { useTranslation } from "react-i18next";
import {  apiCall , manageNetworkError,} from "../assets/js/common";

import bannerImg from "../assets/images/banner.svg";
import aboutImg from "../assets/images/about-us.svg";

import businessContinuity from "../assets/images/business-continuity.svg";
import cyberInsurance from "../assets/images/cyber-Insurance.svg";
import cyberSecurityTrainings from "../assets/images/cyber-security-trainings.svg";
import securityManagement from "../assets/images/security-management.svg";
import securityCompliance from "../assets/images/security-compliance.svg";
import youAsk from "../assets/images/you-ask.svg";
import cruxLogo from "../assets/images/crux-logo.png";
import dataPrivacyManagement from "../assets/images/data-privacy-management.svg";
import cyberTechnicalAssessments from "../assets/images/cyber-technical-assessments-icon.svg";
import otAndIot from "../assets/images/ot-iot-icon.svg";
import nexgenCyberServices from "../assets/images/nexgen-cyber-services.svg";



import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Home({ pageInfo }) {
  const [settingInfo , setSettingInfo] = useState([]);

  const getSettingDetails = async () => {
		const getSettingInfo = await apiCall( 'post' ,  'getSettingInfo' );
		if( getSettingInfo.status !== false ){
			setSettingInfo(getSettingInfo.data.data.settingInfo);
		} else {
			manageNetworkError(getSettingInfo);
		}
	}
  
  useEffect( () => {
	  getSettingDetails(); 
  } , []);  

  const {t} = useTranslation();

  return (
    <Layout pageName={t("home")}>
      
      <section className="section-padding">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-xl-7 col-lg-6 common-left-div common-content-col" data-aos="fade-right" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="first-titel"><span>Consulting</span> and <span>Tools</span> to simplify your cybersecurity requirements</h3>
                <p className="common-desc">From routine GRC Implementations & security assessments to complex cyber risk quantifications and AI risk assessments, we help you simplify your cybersecurity, data privacy and business continuity.</p>
                <div className="common-btn-div">
                  <Link to={Constants.ABOUT_US_PAGE_URL} className="common-btn" title="Know More">Know More</Link>
                  <Link to={Constants.PRODUCTS_AND_SERVICES_PAGE_URL} className="common-btn common-btn2" title={t("products-services")}>{t("products-services")}</Link>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 common-right-div" data-aos="fade-left" data-aos-delay="300">
              <img src={bannerImg} alt="banner" className="common-img img-fluid"/>
            </div>
          </div>
        </div>
      </section>

      {/* about us  */}
      
      <section className="section-padding">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-5 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300"  >
              <img src={aboutImg} alt="About" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-7 order-lg-2 order-1 common-right-div common-content-col" data-aos="fade-left" data-aos-delay="300"  >
              <div className="common-content-div">
                <h1 className="main-title">Cybersecurity with a<span> difference</span></h1>
                <p className="common-desc">We are a fast growing startup managed by people with decades of experience in setting up and running cybersecurity consulting businesses. Our customers are spread across various industries - BFSI, IT & ITES, Software product development among others. We help our clients understand and manage their cybersecurity, business continuity and privacy risks.</p>
                <div className="common-btn-div">
                  <Link to={Constants.ABOUT_US_PAGE_URL} className="common-btn" title="Know More">Know More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* services section */}
      
      <section className="section-padding services-section">
        <div className="container">
          <div className="row justify-content-center" data-aos="fade-down"  >
            <div className="col-lg-8 center-title-div">
                <h3 className="main-title">Our Products<span> and Services</span></h3>
                <p className="common-desc">We specialise in providing a comprehensive range of cybersecurity, information security, privacy, and business continuity products & services.</p>
            </div>
          </div>
          <div className="row row-gap20 row-cols-xl-5 row-cols-md-3 row-cols-sm-2 row-cols-1">
            <div className="col" data-aos="fade-up"   data-aos-delay="100">
              <Link to={Constants.CYBERSECURITY_MANAGEMENT_PAGE_URL} className="services-item" title="Cybersecurity Management">
                <div className="services-icon-div">
                  <img src={securityManagement} alt="Cybersecurity Management" className="services-icon"/>
                </div>
                <h5 className="services-name">Cybersecurity Management</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="100">
              <Link to={Constants.BUSINESS_CONTINUITY_MANAGEMENT_PAGE_URL} className="services-item" title="Business Continuity Management">
                <div className="services-icon-div">
                  <img src={businessContinuity} alt="Business Continuity Management" className="services-icon"/>
                </div>
                <h5 className="services-name">Business Continuity Management</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="400">
              <Link to={Constants.TRAININGS_PAGE_URL} className="services-item" title="Trainings">
                <div className="services-icon-div">
                  <img src={cyberSecurityTrainings} alt="Trainings" className="services-icon"/>
                </div>
                <h5 className="services-name">Trainings</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="200">
              <Link to={Constants.CYBERSECURITY_COMPLIANCE_PAGE_URL} className="services-item" title="Cybersecurity Compliance">
                <div className="services-icon-div">
                  <img src={securityCompliance} alt="Cybersecurity Compliance" className="services-icon"/>
                </div>
                <h5 className="services-name">Cybersecurity Compliance</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="300">
              <Link to={Constants.CYBER_INSURANCE_PAGE_URL} className="services-item" title="Cyber Insurance">
                <div className="services-icon-div">
                  <img src={cyberInsurance} alt="Cyber Insurance" className="services-icon"/>
                </div>
                <h5 className="services-name">Cyber Insurance</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="300">
              <Link to={Constants.CYBER_TECHNICAL_ASSESSMENTS_PAGE_URL} className="services-item" title="Cyber Technical Assessments">
                <div className="services-icon-div">
                  <img src={cyberTechnicalAssessments} alt="Cyber Technical Assessments" className="services-icon"/>
                </div>
                <h5 className="services-name">Cyber Technical Assessments</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="300">
              <Link to={Constants.DATA_PRIVACY_MANAGEMENT_PAGE_URL} className="services-item" title="Data Privacy Management">
                <div className="services-icon-div">
                  <img src={dataPrivacyManagement} alt="Data Privacy Management" className="services-icon"/>
                </div>
                <h5 className="services-name">Data Privacy Management</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="300">
              <Link to={Constants.OT_IOT_PAGE_URL} className="services-item" title="OT and IoT">
                <div className="services-icon-div">
                  <img src={otAndIot} alt="OT and IoT" className="services-icon"/>
                </div>
                <h5 className="services-name">OT and IoT</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="200">
              <Link to={Constants.NEXTGEN_CYBER_SERVICES_PAGE_URL} className="services-item" title="Nexgen Cyber Services">
                <div className="services-icon-div">
                  <img src={nexgenCyberServices} alt="Nexgen Cyber Services" className="services-icon"/>
                </div>
                <h5 className="services-name">Nexgen Cyber Services</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up" data-aos-delay="400">
              <Link to={Constants.CRUX_PAGE_URL} className="services-item2" title="Cyber Risk Underwriting Expert">
                <div className="services-icon-div">
                  <img src={cruxLogo} alt="CRUX" className="services-icon"/>
                </div>
                <p className="services-desc">Cyber Risk Underwriting Expert </p>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* ask section */}

      <section className="section-padding ask-section">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-7 common-left-div common-content-col" data-aos="fade-right" data-aos-delay="200"  >
              <div className="common-content-div">
                <h3 className="main-title">What’s the difference, <span>you ask?</span></h3>
                <p className="common-desc">Cybersecurity today, is either a checklist or a template driven exercise. We are a firm that automates the boring stuff. We take you straight to the thinking part. We know that there are cybersecurity products galore. Most of them are complex behemoths that users never touch the surface of. We want to change that.</p>
                <p className="common-desc">We have always believed in putting the customer front and centre. Our design thinking approach to problem solving helps us achieve just that. We've done personas and customer journey map exercises often. They help us build automated solutions that tackle the customers biggest problems. We plan to build more. We want to automate everything. Except your brains! We want to adopt the AI revolution and make cybersecurity better.</p>
                <div className="call-us-div">
                  <div className="call-us-icon">
                    {/* <div className="call-us-icon-float1"></div>
                    <div className="call-us-icon-float2"></div>
                    <div className="call-us-icon-float3"></div> */}
                    <div className="call-us-icon-in">
                      <i className="fi fi-rr-phone-call"></i>
                    </div>
                  </div>
                  <div className="call-us-content">
                    <span className="call-us-title">Call Us Now</span>
                    {settingInfo?.v_primary_mobile_no && settingInfo.v_primary_mobile_no !== null && (
                      <a href={"tel:" + settingInfo.v_primary_mobile_no.replace(/ /g,'')} className="call-us-link">{settingInfo.v_primary_mobile_no}</a>
                    )}  
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 common-right-div" data-aos="fade-left" data-aos-delay="200"  >
              <img src={youAsk} alt="About" className="common-img img-fluid"/>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}