import React  from "react";
import {Link  } from "react-router-dom";
import Layout from "./layout";
import * as Constants from "../config/constants";
import { useTranslation } from "react-i18next";

import aboutImg from "../assets/images/about-us.svg";
import youAsk from "../assets/images/you-ask.svg";

import cultureImg1 from "../assets/images/put-people-1st.svg";
import cultureImg2 from "../assets/images/think-big.svg";
import cultureImg3 from "../assets/images/think-different.svg";
import cultureImg4 from "../assets/images/open-transparent.svg";
import cultureImg5 from "../assets/images/create-value.svg";



export default function About({ pageInfo }) {
  const { t } = useTranslation();

  return (
    <Layout pageName={t("about-us")}>
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">{t("about-us")}</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{t("about-us")}</li>
          </ol>
        </div>
      </header>
      
      {/* about us  */}
      
      <section className="section-padding">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-5 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300">
              <img src={aboutImg} alt="About" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-7 order-lg-2 order-1 common-right-div common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Cybersecurity with a<span> difference</span></h3>
                <p className="common-desc">We are a fast growing startup managed by people with decades of experience in setting up and running cybersecurity consulting businesses. Our customers are spread across various industries - BFSI, IT & ITES, Software product development among others. We help our clients understand and manage their cybersecurity, business continuity and privacy risks.</p>
                <p className="common-desc">We believe in Cybersecurity with a difference.</p>
                <div className="common-btn-div">
                  <Link to={Constants.CONTACT_US_PAGE_URL} className="common-btn" title="Contact Us Now">Contact Us Now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

       {/* ask section */}

       <section className="section-padding ask-section">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-7 common-left-div common-content-col" data-aos="fade-right" data-aos-delay="200">
              <div className="common-content-div">
                <h3 className="main-title">What's the difference, <span>you ask?</span></h3>
                <p className="common-desc">We believe in putting our customers front and centre. We love to use design thinking for that. We've done personas and customer journey map exercises often. They've helped us build automated solutions that tackle the customers biggest risks and challenges. We plan to build more. We want to automate as much as possible. Except your brains. We are looking to adopt the AI revolution and make cybersecurity better.</p>
                <p className="common-desc">We want to move cybersecurity away from being a checklist or a template driven exercise. We like to automate the boring stuff and take you straight to the thinking part.</p>
                <p className="common-desc">We don't follow a cookie cutter approach. We craft unique solutions applicable and useful for your organisation. Our deliverables are customised to your needs and not from a standard toolkit, unless of course, we helped you create the toolkit!</p>
                <p className="common-desc">Our team has worked with organisations that range from 30 people startups with limited seed funding to global conglomerates with multi country cyber complexities. In each case, our team has provided value to our customers in different ways.</p>
                <p className="common-desc">Cybersecurity. With a difference.</p>
              </div>
            </div>
            <div className="col-lg-5 common-right-div d-flex align-content-center" data-aos="fade-left" data-aos-delay="200">
              <img src={youAsk} alt="About" className="common-img img-fluid"/>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding">
        <div className="container">
        <div className="row justify-content-center" data-aos="fade-down">
            <div className="col-lg-8 center-title-div">
                <h3 className="main-title">Our <span>Culture</span></h3>
            </div>
          </div>
          <div className="row row-gap20 ask-section mb-30">
            <div className="col-lg-8 common-left-div common-content-col" data-aos="fade-right" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Put People <span>1st</span></h3>
                <p className="common-desc">At PentaQube, people always come first and not in the cliched way. We define people as our customers, peers, team members, managers, and business partners  and we prioritise them in every decision we make. From delivering exceptional service to fostering strong relationships, we ensure that everyone in our circle of influence feels valued and supported. Putting people first isn't just a philosophy-it's the foundation of our company culture, guiding us towards success and mutual prosperity. You will have to  experience this philosophy to believe it.</p>
              </div>
            </div>
            <div className="col-lg-4 common-right-div" data-aos="fade-left" data-aos-delay="300">
              <img src={cultureImg1} alt="Services" className="common-img img-fluid"/>
            </div>
          </div>
          <div className="row row-gap20 ask-section mb-30">
            <div className="col-lg-4 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300">
              <img src={cultureImg2} alt="Services" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-8 order-lg-2 order-1 common-right-div common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Think <span>Big</span></h3>
                <p className="common-desc">At PentaQube, we believe in the power of thinking big. For us, BIG is three dimensional: scale, volume, and speed. In whatever we take up, be it project related or internal operational stuff, we consider how it will scale to meet the needs of a growing audience, workload and velocity. By embracing the Think Big principle, we empower our team to innovate and achieve remarkable results. Join us at PentaQube to think big together and make a meaningful impact.</p>
              </div>
            </div>
          </div>
          <div className="row row-gap20 ask-section mb-30">
            <div className="col-lg-8 common-left-div common-content-col" data-aos="fade-right" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Think <span>Different</span></h3>
                <p className="common-desc">PentaQube celebrates the power of thinking differently. Different could mean simple, but beautiful and effective. Embracing fun as a catalyst for creativity, we infuse joy into everything we do. Continuously exploring new horizons, we remain at the forefront of innovation in our ever-evolving industry. We believe in not just thinking new, but in actively creating the new. Join us on this journey of innovation, where thinking differently isn't just a motto-it's our way of life.</p>
              </div>
            </div>
            <div className="col-lg-4 common-right-div" data-aos="fade-left" data-aos-delay="300">
              <img src={cultureImg3} alt="Services" className="common-img img-fluid"/>
            </div>
          </div>
          <div className="row row-gap20 ask-section mb-30">
            <div className="col-lg-4 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300">
              <img src={cultureImg4} alt="Services" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-8 order-lg-2 order-1 common-right-div common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Be <span>Open & Transparent</span></h3>
                <p className="common-desc">At PentaQube, we uphold the values of openness and transparency as foundational principles. We believe in actively listening to ideas and feedback from all with whom we have an association (refer to our circle of influence bit in the “People First” principle), fostering an environment of growth. Our decision-making process is transparent, with clear explanations provided for the choices we make. We openly communicate our goals and challenges, encouraging collaboration and shared problem-solving. By embracing openness and transparency, we cultivate trust and a sense of security within our organisation, empowering everyone to contribute to our collective success.</p>
              </div>
            </div>
          </div>
          <div className="row row-gap20 ask-section">
            <div className="col-lg-8 common-left-div common-content-col" data-aos="fade-right" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Create  <span>Value</span></h3>
                <p className="common-desc">At our company, we're committed to creating value in everything we do. We believe in the power of simplicity, focusing on two core principles: identifying what is valuable to our circle of influence and delivering on that value. By understanding the needs of those we have an association with, we can tailor our efforts to maximise the impact we make. Communication plays a crucial role in ensuring that the value we create is recognized and appreciated. We prefer to over-communicate to ensure that our contributions are fully understood and valued. Join us in our mission to create meaningful value and make a difference.</p>
              </div>
            </div>
            <div className="col-lg-4 common-right-div" data-aos="fade-left" data-aos-delay="300">
              <img src={cultureImg5} alt="Services" className="common-img img-fluid"/>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}