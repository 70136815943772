import React  from "react";
import {Link  } from "react-router-dom";
import Layout from "./layout";
import * as Constants from "../config/constants";
import { useTranslation } from "react-i18next";

export default function About({ pageInfo }) {
  const { t } = useTranslation();

  return (
    <Layout pageName={t("privacy-policy")}>
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">{t("privacy-policy")}</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{t("privacy-policy")}</li>
          </ol>
        </div>
      </header>
      
      {/* about us  */}
      
      <section className="section-padding">
        <div className="container">
        <div className="row justify-content-center" data-aos="fade-down">
            <div className="col-lg-8 center-title-div">
                <h3 className="main-title">Privacy <span>Policy</span></h3>
            </div>
          </div>
          <div className="row row-gap20 ask-section mb-30">
            <p className="common-desc">PentaQube Cyber Technologies LLP ("us", "we", or "our") operates www.pentaqube.com (the "Site"). This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the Site.</p>
            <p className="common-desc">We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy. </p>
          </div>
          <div className="row row-gap20 ask-section mb-30">
            <div className="col-lg-12 common-content-col" data-aos="fade-right" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Information Collection <span>and Use</span></h3>
                <p className="common-desc">While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name ("Personal Information").</p>
              </div>
            </div>
          </div>
          <div className="row row-gap20 ask-section mb-30">
            <div className="col-lg-12 common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Log <span>Data</span></h3>
                <p className="common-desc">Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages, and other statistics. </p>
              </div>
            </div>
          </div>
          <div className="row row-gap20 ask-section mb-30">
            <div className="col-lg-12 common-content-col" data-aos="fade-right" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Cookies</h3>
                <p className="common-desc">Cookies are files with small amounts of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your computer's hard drive. </p>
                <p className="common-desc">Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site. </p>
              </div>
            </div>
          </div>
          <div className="row row-gap20 ask-section mb-30">
            <div className="col-lg-12 common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Security</h3>
                <p className="common-desc">The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security. </p>
              </div>
            </div>
          </div>
          <div className="row row-gap20 ask-section mb-30">
            <div className="col-lg-12 common-content-col" data-aos="fade-right" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Changes to This  <span>Privacy Policy</span></h3>
                <p className="common-desc">This Privacy Policy is effective as of [Date] and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page. </p>
                <p className="common-desc">We reserve the right to update or change our Privacy Policy at any time, and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy. </p>
                <p className="common-desc">If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website. </p>
              </div>
            </div>
          </div>
          <div className="row row-gap20 ask-section">
            <div className="col-lg-12 common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Contact  <span>Us</span></h3>
                <p className="common-desc">If you have any questions about this Privacy Policy, please contact us at <a className="primary-link" href="mailto:reachus@pentaqube.com">reachus@pentaqube.com</a>. </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}