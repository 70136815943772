import React from "react";
import {Link  } from "react-router-dom";
import Layout from "./layout.js";
import * as Constants from "../config/constants";

import { useTranslation } from "react-i18next";

import servicesImg from "../assets/images/cyber-security-assessments.svg";

import servicesIcon1 from "../assets/images/types-of-assessments-icon.svg";

import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Home({ pageInfo }) {

  const {t} = useTranslation();

  return (
    <Layout pageName="Cyber Technical Assessments">
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">Cyber Technical Assessments</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item"><Link  to={Constants.PRODUCTS_AND_SERVICES_PAGE_URL} title={t('products-services')}>{t('products-services')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Cyber Technical Assessments</li>
          </ol>
        </div>
      </header>
      
      {/* Cyber Technical Assessments  */}
      
      <section className="section-padding">
        <div className="container">
          <div className="row row-gap20">
            <div className="col-lg-4 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300">
              <img src={servicesImg} alt="Services" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-8 order-lg-2 order-1 common-right-div common-content-col" data-aos="fade-left" data-aos-delay="300">
              <div className="common-content-div">
                <h3 className="main-title">Cyber <span>Technical Assessments</span></h3>
                <p className="common-desc">If you've forgotten whether the door is locked or not, we are here to help.</p>
                <p className="common-desc">We are the guardians of the digital fortress, keeping it safe from the creatures that seek to exploit its vulnerabilities.</p>
                <p className="common-desc">We're highly skilled in finding weaknesses and testing them out to make sure they're fixed. Whether it's checking for vulnerabilities in apps and websites, or reviewing the code behind them, we're thorough and efficient. We're not just about finding problems, though. We also simulate real-world attacks through red teaming to see how well systems hold up under pressure. Plus, we're on top of the latest cloud security measures, ensuring that data stays safe no matter where it's stored. In a nutshell, we're the go-to experts for keeping your digital assets secure.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding pt-3 services-section">
        <div className="container">
          <div className="row">
            <div className="col-12" data-aos="fade-up" data-aos-delay="300">
              <div className="services-title-div">
                <div className="services-icon-div">
                  <img src={servicesIcon1} alt="Types of assessments" className="services-icon"/>
                </div>
                <h3 className="services-title">Types of Assessments</h3>
              </div>
              <div className="services-content-div">
                <p className="common-desc">We help you assess the state of your cyber infrastructure and recommend the most suitable assessment type.</p>
                <p className="common-desc common-desc-black">Here are some of assessments that we offer:</p>

                <ul className="common-list list-full mb-0" style={{maxWidth: "850px",width: "100%",}}>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Vulnerability Assessment & Penetration Testing</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Web and App Sec</li>
                  <li className="common-list-li mb-sm-0"><i className="fi fi-rr-check-circle"></i>Red Teaming</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Source Code Review</li>
                  <li className="common-list-li"><i className="fi fi-rr-check-circle"></i>Cloud Security Assessment</li>
                  <li className="common-list-li mb-sm-0"><i className="fi fi-rr-check-circle"></i>Configuration reviews </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      

    </Layout>
  );
}